import { eventN } from "@ksense/eventnative";

const initKsense = () => {
  eventN.init({
    key: "56fa4b2d-107c-41dc-aa0f-81f7360558d2",
    tracking_host: "https://analytics.xobalabs.com",
    segment_hook: true,
  });
};

export default initKsense;
